import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { SearchBox } from 'components/search/SearchBox';
import { Paragraph } from 'components/core/Typography';
import { StickyContainer } from 'components/core/StickyContainer';

import './StickySearchBar.scss';

export default function StickySearchBar({
	handleSearch,
	triggerId,
	// sf_12700_sfdc_paid_landing_refresh
	clickToCall,
	getTollfreePhoneNumber,
	parentPhoneNumber,
	setParentPhoneNumber,
	parentPhoneLoaded,
	setParentPhoneLoaded,
	className,
	hasJumpToSection,
	defaultButton
}) {
	// pass the id of the component you want to
	// activate the Search Bar as triggerId prop

	// otherwise, the sticky search bar will be activated
	// when scrolled to where it is rendered on the page
	return (
		<StickyContainer triggerId={triggerId}>
			<div className={classnames('sticky-search-bar', className || '', { clickToCall })}>
				<div className='search-bar'>
					<Paragraph size="medium" className="search-box-label" color="black" last>
						Find Storage Units Near You
					</Paragraph>
					<SearchBox
						inputLabel="Enter ZIP code"
						handleSearch={handleSearch}
						segmentPrefix="sticky search box"
						hideOnScroll
						buttonText={
							<span>Find storage</span>
						}
						pinkButton={!defaultButton && !!hasJumpToSection}
						darkButton={!defaultButton && !hasJumpToSection}
						
						// sf_12700_sfdc_paid_landing_refresh
						clickToCall={clickToCall}
						getTollfreePhoneNumber={getTollfreePhoneNumber}
						parentPhoneNumber={parentPhoneNumber}
						setParentPhoneNumber={setParentPhoneNumber}
						parentPhoneLoaded={parentPhoneLoaded}
						setParentPhoneLoaded={setParentPhoneLoaded}
					/>
				</div>
				{hasJumpToSection && hasJumpToSection}
			</div>
		</StickyContainer>
	);
}

StickySearchBar.propTypes = {
	handleSearch: PropTypes.func.isRequired,
	triggerId: PropTypes.string.isRequired,
	// sf_12700_sfdc_paid_landing_refresh
	clickToCall: PropTypes.bool,
	getTollfreePhoneNumber: PropTypes.func,
	parentPhoneNumber: PropTypes.object,
	setParentPhoneNumber: PropTypes.func,
	parentPhoneLoaded: PropTypes.bool,
	setParentPhoneLoaded: PropTypes.func,
	className: PropTypes.string,
	hasJumpToSection: PropTypes.element,
	defaultButton: PropTypes.bool
};
