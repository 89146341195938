import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';

import { Paragraph } from 'components/core/Typography';
import ReviewRating from 'components/review/Review/ReviewRating';

import './Review.scss';

export const enhance = compose(
	withProps((props) => {
		const reviewSchema = {
			'@context': 'http://schema.org/',
			'@type': 'Review',
			itemReviewed: {
				'@type': 'SelfStorage',
				image: props.images,
				name: 'Self-Storage Unit',
				address: 'SpareFoot, Inc. 10900 Research Blvd 160C PMB 3099 Austin, TX 78759'
			},
			reviewRating: {
				'@type': 'Rating',
				ratingValue: props.reviewRating
			},
			name: props.reviewTitle,
			author: {
				'@type': 'Person',
				name: props.reviewerName
			},
			reviewBody: props.reviewText,
			publisher: {
				'@type': 'Organization',
				name: 'SpareFoot',
				address: 'SpareFoot, Inc. 10900 Research Blvd 160C PMB 3099 Austin, TX 78759'
			}
		};

		const stringifiedReviewSchema = JSON.stringify(reviewSchema);
		return { reviewSchema: stringifiedReviewSchema };
	})
);

export function Review(props) {
	return (
		<div className="review-card">
			<div className="title-section">
				<Paragraph className="review-title" size="medium">
					{props.reviewTitle}
				</Paragraph>
				<ReviewRating
					reviewRating={props.reviewRating}
					reviewRatingOutOf={5}
				/>
			</div>
			<Paragraph className="sf-type-black" weight="standard">
				{props.reviewText}
			</Paragraph>
			<Paragraph className="reviewer" last>
				<span className="initials sf-type-weight-bold">{props.reviewerInitials}</span>
				<span className="sf-type-weight-bold"> by {props.reviewerName} </span>
				{props.reviewDate && <span className="review-date sf-type-weight-standard">on {props.reviewDate}</span>}
			</Paragraph>
			{props.reviewSchema &&
				<script
					key="schema"
					type="application/ld+json"
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{
						__html: props.reviewSchema
					}}
				/>
			}
		</div>
	);
}

Review.propTypes = {
	reviewerName: PropTypes.string,
	reviewTitle: PropTypes.string,
	reviewText: PropTypes.string,
	reviewRating: PropTypes.number,
	reviewerInitials: PropTypes.string,
	reviewDate: PropTypes.string,
	reviewSchema: PropTypes.string
};

export default enhance(Review);
